<template>
  <overlay-scrollbars class="page page-request">
    <div class="box box_payments">
      <div class="header">
        <div class="title">Edit Your Offer</div>
      </div>
      <div class="content">
        <div class="block block__bankAcc">
          <div class="main" :class="{'position-relative': +request.progress_stage >= 20}">
            <div class="box-group">
              <div class="box box_left box_tmblers">
                <div class="box_ttl">Bank Type</div>
                <div class="box_list" :key="bankTypesIndex">
                  <div v-for="(item, key) in bankTypes" :key="key" class="list_item">
                    <Tmblr :class="{'pointer-events-none': +request.progress_stage >= 20}" :active="item.active" :text="item.name" @changeState="bankTypesChangeState(item)" />
                  </div>
                </div>
                <div v-if="showOtherBankType">
                  <div class="box_ttl">Bank type</div>
                  <input v-model="request.other_bank_type" type="text" class="inpt inpt_acc" placeholder="Enter bank type">
                </div>
                <div class="box-icon_wrap">
                  <BankTypeIcon class="box-icon" />
                </div>
              </div>
              <div class="box box_left box_tmblers">
                <div class="box_ttl">Country</div>
                <div class="box_list" :key="countriesIndex">
                  <div v-for="(item, key) in countries" :key="key" class="list_item">
                    <Tmblr :class="{'pointer-events-none': +request.progress_stage >= 20}" :active="item.active" :text="item.name" @changeState="countriesChangeState(item)" />
                  </div>
                </div>
                <div v-if="showOtherCountry">
                  <div class="box_ttl">Country</div>
                  <select class="inpt" style="width: 100%;" v-model="request.other_country" @change="selectOtherCountry(request.other_country)">
                    <option value="null">Select country</option>
                    <option v-for="item in allCountries" :key="item.id" :value="item">{{ item.name }}</option>
                  </select>
                </div>
                <div class="box-icon_wrap">
                  <CountryIcon class="box-icon" />
                </div>
              </div>
              <div class="box--blur__wrapper position-relative">
                <div class="box box_left box_tmblers" :class="{'box--blur': !isCountrySelected}">
                  <div class="box_ttl">
                    <span>
                      License Type
                    </span>
                    <span class="box_price">
                      {{ parseInt(request.license_price) | numberFormat }}
                    </span>
                  </div>
                  <div class="box_list" :key="licenseTypesIndex">
                    <template v-if="!isCountrySelected">
                        <div class="list_item" v-for="(item, key) in licenseTypes.slice(0, 5)" :key="key">
                            <Tmblr :class="{'pointer-events-none': +request.progress_stage >= 20}" 
                              :active="item.active" 
                              :text="item.name" 
                              :price="item.pivot && item.pivot.price ? +item.pivot.price : +item.price" 
                              @changeState="licenseTypesChangeState(item)" />
                            <span 
                              v-if="item.active" 
                              class="add-dataset" 
                              @click.prevent="processDataset('licenseTypes', item)">
                              <Info />
                            </span>
                        </div>
                    </template>
                    <template v-else>
                        <div class="list_item" v-for="(item, key) in licenseTypes" :key="key">
                            <Tmblr :class="{'pointer-events-none': +request.progress_stage >= 20}" 
                              :active="item.active" 
                              :text="item.name" 
                              :price="item.pivot && item.pivot.price ? +item.pivot.price : +item.price" 
                              :salePrice="+item.sale_price"
                              @changeState="licenseTypesChangeState(item)" />
                              <span 
                                v-if="item.active && +request.progress_stage >= 20" 
                                class="add-dataset" 
                                @click.prevent="processDataset('licenseTypes', item)">
                              <Info />
                            </span>
                        </div>
                        <div v-if="showOtherLicenseType">
                          <div class="box_ttl">Other License Type</div>
                          <input v-model="request.other_license_type" type="text" class="inpt inpt_acc" placeholder="Enter license type">
                      </div>
                    </template>
                  </div>
                  <div class="box-icon_wrap">
                    <LicenseTypeIcon class="box-icon" />
                  </div>
                </div> 
                <div class="box_overlay--blur" v-if="!isCountrySelected">
                  <h4>Select a country to see available licenses.</h4>
                </div>
              </div>
            </div>
            <div class="box-group">
              <div class="box box_left box_tmblers">
                <div class="box_ttl">
                  <span>
                    Software Type
                  </span>
                  <span class="box_price">
                    {{ parseInt(request.software_price) | numberFormat }}
                  </span>
                </div>
                <div class="box_list row-list" :key="softwareTypesIndex">
                  <div v-for="(item, key) in softwareTypes" :key="key" class="list_item">
                    <Tmblr :class="{'pointer-events-none': +request.progress_stage >= 20}" 
                      :active="item.active" 
                      :text="item.name" :price="item.price ? +item.price : 0" 
                      :salePrice="+item.sale_price"
                      @changeState="softwareTypesChangeState(item)" />
                    <span 
                      v-if="item.active && +request.progress_stage >= 20" 
                      class="add-dataset" 
                      @click.prevent="processDataset('softwareTypes', item)">
                      <Info />
                    </span>
                  </div>
                </div>
                <div v-if="showOtherSoftwareType">
                  <div class="box_ttl">Software type</div>
                  <input v-model="request.other_software_type" type="text" class="inpt inpt_acc" placeholder="Enter software type">
                </div>
                <div class="box-icon_wrap software">
                  <SoftwareTypeIcon class="box-icon" />
                </div>
              </div>
            </div>
            <div class="box-group">
              <div class="box box_left box_tmblers">
                <div class="box_ttl">
                  <span>
                    Account Type
                  </span>
                  <span class="box_price">
                    {{ parseInt(request.account_price) | numberFormat }}
                  </span>
                </div>
                <div class="box_list" :key="accountTypesIndex">
                  <div v-for="(item, key) in accountTypes" :key="key" class="list_item">
                    <Tmblr :class="{'pointer-events-none': +request.progress_stage >= 20}" 
                      :active="item.active" 
                      :text="item.name" 
                      :price="item.price ? +item.price : 0"
                      :salePrice="+item.sale_price" 
                      @changeState="accountTypesChangeState(item)" />
                    <span 
                      v-if="item.active && +request.progress_stage >= 20" 
                      class="add-dataset" 
                      @click.prevent="processDataset('accountTypes', item)">
                      <Info />
                    </span>
                  </div>
                </div>
                <div v-if="showOtherAccountType">
                  <div class="box_ttl">Account type</div>
                  <input v-model="request.other_account_type" type="text" class="inpt inpt_acc" placeholder="Enter account type">
                </div>
                <div class="box-icon_wrap">
                  <AccountTypeIcon class="box-icon" />
                </div>
              </div>
              <div class="box box_left box_tmblers">
                <div class="box_ttl">
                  <span>
                    Membership Type
                  </span>
                  <span class="box_price">
                    {{ parseInt(request.membership_price) | numberFormat }}
                  </span>
                </div>
                <div class="box_list" :key="membershipTypesIndex">
                  <div v-for="(item, key) in membershipTypes" :key="key" class="list_item">
                    <Tmblr :class="{'pointer-events-none': +request.progress_stage >= 20}" 
                      :active="item.active" 
                      :text="item.name" 
                      :price="item.price ? +item.price : 0"
                      :salePrice="+item.sale_price"
                      @changeState="membershipTypesChangeState(item)" />
                    <span 
                      v-if="item.active && +request.progress_stage >= 20" 
                      class="add-dataset" 
                      @click.prevent="processDataset('membershipTypes', item)">
                      <Info />
                    </span>
                  </div>
                </div>
                <div v-if="showOtherMembershipType">
                  <div class="box_ttl">Membership type</div>
                  <input v-model="request.other_membership_type" type="text" class="inpt inpt_acc" placeholder="Enter membership type">
                </div>
                <div class="box-icon_wrap">
                  <MembershipTypeIcon class="box-icon" />
                </div>
              </div>
              <div class="box box_left box_tmblers">
                <div class="box_ttl">
                  <span>
                    Operetional Type
                  </span>
                  <span class="box_price">
                    {{ parseInt(request.operetional_price) | numberFormat }}
                  </span>
                </div>
                <div class="box_list" :key="operetionalTypesIndex">
                  <div v-for="(item, key) in operetionalTypes" :key="key" class="list_item">
                    <Tmblr :class="{'pointer-events-none': +request.progress_stage >= 20}" 
                      :active="item.active" 
                      :text="item.name" 
                      :price="item.price ? +item.price : 0" 
                      :salePrice="+item.sale_price"
                      @changeState="operetionalTypesChangeState(item)" />
                    <span 
                      v-if="item.active && +request.progress_stage >= 20" 
                      class="add-dataset" 
                      @click.prevent="processDataset('operetionalTypes', item)">
                      <Info />
                    </span>
                  </div>
                </div>
                <div v-if="showOtherOperetionalType">
                  <div class="box_ttl">Operetional type</div>
                  <input v-model="request.other_operetional_type" type="text" class="inpt inpt_acc" placeholder="Enter operetional type">
                </div>
                <div class="box-icon_wrap">
                  <OperetionalTypeIcon class="box-icon" />
                </div>
              </div>
            </div>
            <div class="box-group">
              <div class="box box_left box_tmblers">
                <div class="box_ttl">
                  <span>
                    Other services
                  </span>
                  <span class="box_price">
                    {{ parseInt(request.other_price) | numberFormat }}
                  </span>
                </div>
                <div class="row" v-if="!(+request.progress_stage >= 20)">
                  <div class="col-other-services">
                    <input 
                      class="inpt flexible"
                      type="text" 
                      name="other_service" 
                      v-model="otherServiceName" 
                      @keyup.enter="crateOtherService" 
                    />
                    <button 
                      type="button" 
                      class="btn btn_submit" 
                      @click.prvent="crateOtherService"
                    >
                      Add
                    </button>
                  </div>
                  <div class="col-other-services">
                    <select 
                      class="inpt" 
                      name="other_service_select" 
                      v-model="otherServicesSelect"
                      @change="handleSelectChange"
                    >
                      <option 
                        :value="null" 
                        selected
                      >
                        Select other service
                      </option>
                      <option 
                        v-for="option in otherServicesList"
                        :key="option.id" 
                        :value="option"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="box_list row-list flex-wrap" :key="otherServicesIndex">
                  <div v-for="(item, key) in otherServices" :key="key" class="list_item other-services-tmblr-col">
                    <Tmblr :class="{'pointer-events-none': +request.progress_stage >= 20}"
                      :active="item.active" 
                      :text="item.name" 
                      :price="item.price ? +item.price : 0" 
                      :salePrice="+item.sale_price"
                      @changeState="otherServicesChangeState(item)" />
                    <span 
                      v-if="item.active && +request.progress_stage >= 20" 
                      class="add-dataset" 
                      @click.prevent="processDataset('otherServices', item)">
                      <Info />
                    </span>
                  </div>
                </div>
                <!--<div class="box-icon_wrap software">
                  <SoftwareTypeIcon class="box-icon" />
                </div>-->
              </div>
            </div>
            <div v-if="+request.progress_stage >= 20" class="disabled-overlay"></div>
          </div>
        </div>
        <div class="footer">
          <div class="d-flex w-100" v-if="+request.progress_stage >= 20">
            <div class="impulse-info-wrap">
              <Info />
              Check Your Progress & Documents Status
            </div>
          </div>
          <div class="d-flex w-100 total-price__wrap">
            <h5 class="total-price__title">
              Request Total Price<sup>*</sup>
            </h5>
            <h5 class="total-price__value" v-if="request.price">
              {{ request.price | numberFormat }}€
            </h5>
          </div>
          <div class="d-flex w-100 total-price__wrap">
            <small>
              Price is not final; it may change after contact. 
            </small>
          </div>
          <div v-if="!(+request.progress_stage >= 20)" @click="submit" class="btn btn_submit">Submit data</div>
        </div>
      </div>
    </div>
    <div v-if="isShowDatasetModal" class="modal">
      <div class="modal__back" @click.self="closeDataset" />
      <div class="modal__wrapper">
        <div class="modal__close">
          <div class="btn" @click.prevent="closeDataset">
            
          </div>
        </div>
        <div class="modal__header">
          <div class="ttl">
            Information
          </div>
        </div>
        <div class="modal__body page-request main">
          <div class="row" v-if="currentDataset.note">
            <div class="label">Note</div>
            <div class="data">
              {{ currentDataset.note }}
            </div>
          </div>
          <div class="row" v-if="+currentDataset.progress_stage > 0">
            <div class="label">
              Progress Stage - {{ currentDataset.progress_stage }}%
            </div>
          </div>
          <div class="row row_files">
            <div class="block block_files">
              <div class="box box_addFiles">
                <div class="ttl">Documents</div>
                <div v-if="getUploadedFiles('docs').length" class="content content_many">
                  <draggable v-model="currentDataset.datasetUpload.docs" tag="div" class="draggable">
                  <div v-for="(file, key) in getUploadedFiles('docs')" :key="key" class="file">
                    <File class="img" />
                    <div class="btn btn_del" @click="delFile('docs', key, file)"><Close class="img" /></div>
                    <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'docs')"><BlueDownload class="img" /></div>
                    <div class="desc">{{ file.name }}</div>
                  </div>
                  </draggable>
                  <div class="add">
                    <div class="btn" @click="$refs.docs[currentDataset.datasetUpload.docs.length - 1].click()">
                      <Close class="img" />
                    </div>
                    <div class="desc">(max 5 MB)</div>
                  </div>
                </div>
                <div v-else class="content">
                  <div class="choose">
                    <div class="btn" @click="$refs.docs[0].click()">Documents</div>
                    <div class="desc">(max 5 MB)</div>
                  </div>
                </div>
                  <input
                    v-for="(i, key) in currentDataset.datasetUpload.docs"
                    :key="key"
                    type="file"
                    :name="`docs_${key}`"
                    class="hide"
                    ref="docs"
                    @change="addInput($event, 'docs', key)"
                  >
              </div>
            </div>
          </div>
        </div>
        <div class="modal__footer footer">
          <div class="btn btn_submit" @click.prevent="submitDataset">
            Save Data
          </div>
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Tmblr from '@/components/elem/Tmblr.vue'
import Vue from 'vue'
import BankTypeIcon from '@/components/img/BankTypeIcon'
import SoftwareTypeIcon from '@/components/img/SoftwareTypeIcon'
import LicenseTypeIcon from '@/components/img/LicenseTypeIcon'
import OperetionalTypeIcon from '@/components/img/OperetionalTypeIcon'
import MembershipTypeIcon from '@/components/img/MembershipTypeIcon'
import AccountTypeIcon from '@/components/img/AccountTypeIcon'
import CountryIcon from '@/components/img/CountryIcon'
import Info from '@/components/img/Info'
import File from '@/components/img/File.vue'
import draggable from 'vuedraggable'
import BlueDownload from '@/components/img/BlueDownload.vue'
import Close from '@/components/img/Close.vue'

export default {
  components: {
    Tmblr,
    BankTypeIcon,
    SoftwareTypeIcon,
    LicenseTypeIcon,
    OperetionalTypeIcon,
    MembershipTypeIcon,
    AccountTypeIcon,
    CountryIcon,
    Info,
    File,
    draggable,
    BlueDownload,
    Close
  },
  data() {
    return {
      userBankTypes: [],
      userLicenseTypes: [],
      userAccountTypes: [],
      userMembershipTypes: [],
      userOperetionalTypes: [],
      userSoftwareTypes: [],
      userOtherServices: [],
      userCountries: [],

      bankTypes: [],
      bankTypesIndex: 0,
      licenseTypes: [],
      licenseTypesIndex: 0,
      allLicenseTypes: [],
      allLicenseTypesIndex: 0,
      membershipTypes: [],
      membershipTypesIndex: 0,
      operetionalTypes: [],
      operetionalTypesIndex: 0,
      accountTypes: [],
      accountTypesIndex: 0,
      softwareTypes: [],
      softwareTypesIndex: 0,
      countries: [],
      countriesIndex: 0,
      allCountries: [],
      allCountriesIndex: 0,
      otherServices: [],
      allOtherServices: [],
      otherServicesList: [],
      otherServicesSelect: null,
      otherServicesIndex: 0,
      otherServiceName: '',

      isShowDatasetModal: false,
      currentDataset: this.getEmptyDataset(),
      currentDatasetStep: null,
      currentDatasetStepId: null,

      showOtherAccountType: false,
      showOtherBankType: false,
      showOtherMembershipType: false,
      showOtherLicenseType: false,
      showOtherOperetionalType: false,
      showOtherSoftwareType: false,
      showOtherCountry: false,
      isCountrySelected: false,
      isAllLicenseTypesFetchedBlock: false,

      totalPrice: 0,
      originalTotalPrice: 0,

      request: {
        id: '',
        other_bank_type: '',
        other_license_type: '',
        other_membership_type: '',
        other_operetional_type: '',
        other_account_type: '',
        other_software_type: '',
        other_country: '',
      },
      original_request: {},
      errors: {
        other_bank_type: '',
        other_license_type: '',
        other_membership_type: '',
        other_operetional_type: '',
        other_software_type: '',
        other_account_type: '',
      }
    }
  },
  async mounted(){
    try {
      this.request.id = this.$route.params.id,

      await this.getRequest();
      //await this.getCountries();
      await this.getLicenseTypes();
      await this.getBankTypes();
      await this.getAccountTypes();
      await this.getMembershipTypes();
      await this.getOperetionalTypes();
      await this.getSoftwareTypes();
      await this.getAllCountries();
      await this.getOtherServices();
      await this.setOtherServices();

      this.isCountrySelected = this.getIsCountrySelected();

      setTimeout(() => {
        this.setRequestPrices();
      }, 3000);
    }
    catch (error) {
      console.error("Error occurred:", error);
    }
  },
  methods: {
    async processDataset(step, item){
      let datasetId = null;

      this.currentDatasetStep = step
      this.currentDatasetStepId = item.id

      if(this.request[step].length){
        for (let i = 0; i < this.request[step].length; i++) {
          const el = this.request[step][i];
          
          if(el.id == item.id){
            datasetId = el.pivot.license_offer_dataset_id
          }
        }
      }

      this.currentDataset = await this.getDataset(step, item, datasetId);

      this.isShowDatasetModal = true;
    },
    setPivotDatasetId(datasetId){
      let step = this.currentDatasetStep;
      let id = this.currentDatasetStepId;
      
      if(this[step].length){
        for (let i = 0; i < this[step].length; i++) {
          const el = this[step][i];
          
          if(el.id == id){
            el.license_offer_dataset_id = datasetId
          }
        }
      }
    },
    closeDataset(){
      this.currentDataset = this.getEmptyDataset();
      this.currentDatasetStep = null;
      this.currentDatasetStepId = null;

      this.isShowDatasetModal = false;
    },
    async submitDataset(){
      let url = process.env.VUE_APP_BACKEND_API_URL + 'license-offers/dataset'

      if(this.currentDataset.id){
        url += '/' + this.currentDataset.id
      }

      try {
        const response = await axios.post(url, {
          note: this.currentDataset.note,
          progress_stage: this.currentDataset.progress_stage,
        });
        let datasetId = response.data.id;

        await this.processFiles(datasetId)

        this.setPivotDatasetId(datasetId)

        this.submit(true)

        this.closeDataset()
      }
      catch (error) {
        console.log(error);
      }
    },
    async processFiles(id){
      let data = new FormData()
      const uploadKeys = Object.keys(this.currentDataset.datasetUpload)

      for (let z = 0; z < uploadKeys.length; z++) {
        const key = uploadKeys[z]

        for (let i = 0; i < this.currentDataset.datasetUpload[key].length; i++) {
          if (this.currentDataset.datasetUpload[key][i].name !== null) {
            let f = {}
            if (this.currentDataset.datasetUpload[key][i].type == 'server') {
              f = JSON.stringify(this.currentDataset.datasetUpload[key][i])
            } else {
              data.append(key + '_names[' + i + ']', this.currentDataset.datasetUpload[key][i].name)
              f = this.currentDataset.datasetUpload[key][i].file
            }
            data.append(key + '[' + i + ']', f)
          }
        }
      }
      
      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'license-offers/dataset/' + id + '/files', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((response) => {
          
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    async getDataset(step, item, datasetId){
      if(datasetId == null){
        return this.getEmptyDataset()
      }

      try {
        const response = await axios.get(process.env.VUE_APP_BACKEND_API_URL + 'license-offers/dataset/' + datasetId);
        let dataset = response.data;
        
        dataset.datasetUpload = {docs: await this.getDatasetDocs(datasetId)};

        return dataset;
      }
      catch (error) {
        console.log(error);
      }
    },
    async getDatasetDocs(id) {
      if(id) {
        try {
          const response = await axios.get(process.env.VUE_APP_BACKEND_API_URL + 'license-offers/dataset/' + id + '/get-file/docs');
          
          let docsData = response.data
          
          if (docsData.length > 0) {
            return docsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              };
            });
          }

          return [{
            id: null,
            uuid: null,
            name: null,
            type: '',
            url: null,
            order: null
          }]
        }
        catch (error) {
          console.dir(error);
        }
      }
    },
    getEmptyDataset(){
      return {
        progress_stage: 0,
        note: '',
        datasetUpload: {
          docs: [{ name: null }],
        }
      }
    },
    getUploadedFiles(el) {
      return this.currentDataset.datasetUpload[el].filter(e => e.name !== null)
    },
    delFile(name, key, file) {
      if (this.currentDataset.datasetUpload[name].length > 1) {
        this.currentDataset.datasetUpload[name].splice(key, 1)
      } else {
        this.currentDataset.datasetUpload[name][0].name = null
      }

      if(file.id && this.currentDataset.id){
        axios.post(process.env.VUE_APP_BACKEND_API_URL + 'license-offers/dataset/' + this.currentDataset.id +'/delete-file', {
        collection: name,
        media_id: file.id,
      })
        .then(function () {
          
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    addInput(event, name, key) {
      if (this.$refs[name][key].files.length) {
        const file_name = this.$refs[name][key].files[0].name
        if (this.getUploadedFiles(name).some(e => e.name == file_name)) {
          this.$noty.error('Error! A file with this name has already been added.')
          return false
        }

        const extension = this.$refs[name][key].files[0].name.split('.').pop()
        const availableExtensionsArr = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx', 'xlsx', 'zip', 'rar', '7z', 'tar']

        if (!availableExtensionsArr.includes(extension.toLowerCase())) {
          this.$noty.error('You can upload files only with these extensions: ' + availableExtensionsArr.join(', '))
          return false
        }

        if (this.$refs[name][key].files[0].size / 1024 / 1024 > 5) {
          this.$noty.error('File is too large. Maximum - 5MB.')
          return false
        }

        this.currentDataset.datasetUpload[name] = this.currentDataset.datasetUpload[name].filter(e => e.name !== null)
        if (this.$refs[name].length < 2) {
          this.currentDataset.datasetUpload[name].push({ name: file_name, file: event.target.files[0]})
        } else {
          this.$noty.error('Maximum - 2 files')
        }
      }
    },
    downloadFile(file, type) {
      const id = this.currentDataset.id

      if(file.url){
        this.openFile(file.url)
      }
      else if(id){
        axios.post(process.env.VUE_APP_BACKEND_API_URL + 'license-offers/dataset/' + id +'/get-file-link', {
        collection: type,
        media_id: file.id,
      })
        .then(function (response) {
          if (response.data) {
            const url = response.data
            var fileURL = response.data;
            this.openFile(fileUrl);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    openFile(url){
      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.setAttribute('target', '_blank')
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    setRequestPrices(){
      const parts = ['licenseTypes', 'softwareTypes', 'accountTypes', 'membershipTypes', 'operetionalTypes', 'otherServices'];
      let self = this;

      parts.forEach((part) => {
        if(this.request.hasOwnProperty(part) && this.hasOwnProperty(part)){
          const requestValues = this.request[part]
          let values = this[part]
          
          requestValues.forEach((item, i) => {
            if(item.pivot && item.pivot.price != undefined && +item.pivot.price != +item.price){
              let el = values.find(value => value.id === item.id && value.active === true);

              self.$set(el, 'edit_price', true);
              self.$set(el, 'sale_price', item.pivot.price)
            }
          });
        }
      });
    },
    clearErrors () {
      for (let key in this.errors) {
        this.errors[key] = ''
      }
    },
    getFormattedPriceArray(array) {
      let totalArrayPrice = 0;
      
      const formattedArray = array.reduce(function(acc, el) {
        let price = el.pivot && el.pivot.price !== undefined ? +el.pivot.price : +el.price;
        let datasetId = el.license_offer_dataset_id !== undefined || el.license_offer_dataset_id !== null ? el.license_offer_dataset_id : null

        price = el.sale_price != undefined ? +el.sale_price : +price;
        
        acc[el.id] = { price: +price, license_offer_dataset_id: datasetId };
        totalArrayPrice += +acc[el.id].price;

        return acc;
      }, {});

      return { formattedArray, totalArrayPrice };
    },
    submit(isForDatasetSubmit = false) {
      this.clearErrors()

      const that = this
      const id = this.request.id;

      const userBankTypeIds = this.bankTypes.filter(el => el.active).map(el => el.id);
      let userCountriesIds = this.countries.filter(el => el.active && el.id !== null).map(el => el.id);

      if(this.request.other_country && this.request.other_country.id){
        userCountriesIds.push(this.request.other_country.id)
      }

      const getPriceData = (array) => {
        const { formattedArray, totalArrayPrice } = this.getFormattedPriceArray(array);

        return { ids: array, price: totalArrayPrice, formattedArray };
      };

      const dataMap = {
        licenseTypes: getPriceData(this.licenseTypes.filter(el => el.active)),
        softwareTypes: getPriceData(this.softwareTypes.filter(el => el.active)),
        accountTypes: getPriceData(this.accountTypes.filter(el => el.active)),
        membershipTypes: getPriceData(this.membershipTypes.filter(el => el.active)),
        operetionalTypes: getPriceData(this.operetionalTypes.filter(el => el.active)),
        otherServices: getPriceData(this.otherServices.filter(el => el.active)),
      };
      
      axios.put(process.env.VUE_APP_BACKEND_API_URL + 'license-offers/' + id, {
        bank_types: userBankTypeIds,
        license_types: dataMap.licenseTypes.formattedArray,
        software_types: dataMap.softwareTypes.formattedArray,
        account_types: dataMap.accountTypes.formattedArray,
        membership_types: dataMap.membershipTypes.formattedArray,
        operetional_types: dataMap.operetionalTypes.formattedArray,
        other_services: dataMap.otherServices.formattedArray,
        license_price: this.request.license_price,
        software_price: this.request.software_price,
        account_price: this.request.account_price,
        membership_price: this.request.membership_price,
        operetional_price: this.request.operetional_price,
        other_price: this.request.other_price,
        countries: userCountriesIds,
        price: this.request.price,
        other_bank_type: this.request.other_bank_type,
        other_account_type: this.request.other_account_type,
        other_license_type: this.request.other_license_type,
        other_membership_type: this.request.other_membership_type,
        other_operetional_type: this.request.other_operetional_type,
        other_software_type: this.request.other_software_type,
      }).then(function (response) {
          if (response.data.error) {
            for (let serverError in response.data.errors) {
              for (let cilentError in that.errors) {
                if (serverError === cilentError && response.data.errors[serverError][0]) {
                  that.errors[cilentError] = response.data.errors[serverError][0]  
                }
              }
            }
            return
          }
          
          if(typeof isForDatasetSubmit === 'object'){
            that.$noty.success("Your request data was successfully changed")

            that.$router.push({ name: 'YouOffers' })
          }
          else{
            that.getRequest();
          }
        }).catch(function (error) {
          console.log(error);
          if (error) {
            for (let serverError in error.errors) {
              for (let cilentError in that.errors) {
                if (serverError === cilentError && response.data.errors[serverError][0]) {
                  that.errors[cilentError] = response.data.errors[serverError][0]  
                }
              }
            }
            return
          }

          that.$noty.error("Your request data is not correct")
        })
    },
    crateOtherService(){
      if(this.otherServiceName){
        const that = this

        axios.post(process.env.VUE_APP_BACKEND_API_URL + 'license-offers/other-service', {
          name: this.otherServiceName
        })
        .then(function (response) {
          if(response.data.success){
            let otherService = response.data.otherService

            otherService.active = true
            
            that.otherServices.push(otherService)

            that.otherServicesIndex++

            that.otherServiceName = ''

            that.getOtherServices()
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    bankTypesChangeState (item) {
      const index = this.bankTypes.findIndex(bankType => {
          return (item.id === bankType.id)
      })

      this.unActiveItem(this.bankTypes, index, 'showOtherBankType')

      this.$set(this.bankTypes[index], 'active', !item.active)
      if (this.bankTypes[index].name == 'Other') {
        if (this.bankTypes[index].active) {
          this.showOtherBankType = true
        } else {
          this.showOtherBankType = false
          this.request.other_bank_type = ''
        }
      }
      
      this.bankTypesIndex++
    },
    softwareTypesChangeState (item) {
      const index = this.softwareTypes.findIndex(softwareType => {
          return (item.id === softwareType.id)
      })

      this.unActiveItem(this.softwareTypes, index, 'showOtherSoftwareType')

      this.$set(this.softwareTypes[index], 'active', !item.active)
      if (this.softwareTypes[index].name == 'Other') {
        if (this.softwareTypes[index].active) {
          this.showOtherSoftwareType = true
        } else {
          this.showOtherSoftwareType = false
          this.request.other_software_type = ''
        }
      }

      this.calculateTotalPrice(true);
      
      this.softwareTypesIndex++
    },
    licenseTypesChangeState (item) {
      const index = this.licenseTypes.findIndex(licenseType => {
          return (item.id === licenseType.id)
      })

      this.unActiveItem(this.licenseTypes, index, 'showOtherLicenseType')

      this.$set(this.licenseTypes[index], 'active', !item.active)
      if (this.licenseTypes[index].name == 'Other') {
        if (this.licenseTypes[index].active) {
          this.showOtherLicenseType = true
        } else {
          this.showOtherLicenseType = false
          this.request.other_license_type = ''
        }
      }

      this.calculateTotalPrice(true);
      
      this.licenseTypesIndex++
    },
    getOtherServices() {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/other-services?license_offer=' + this.request.id)
        .then(function (response) {
          that.allOtherServices = response.data
          that.otherServicesList = that.filterOtherServices(response.data)
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async setOtherServices(){
      let otherServices = this.userOtherServices

      for(let index = 0; index < otherServices.length; index++) {
        otherServices[index].active = true
      }

      this.otherServices = otherServices
    },
    filterOtherServices(items){
      let filtered = items.filter(item => {
        return !this.otherServices.some(selectedItem => selectedItem.id == item.id)
      })

      return filtered
    },
    handleSelectChange(){
      if(this.otherServicesSelect){
        let item = this.otherServicesSelect

        item.active = true

        this.otherServices.push(item)

        this.otherServicesIndex++

        this.otherServicesSelect = null

        this.getOtherServices()
      }
    },
    otherServicesChangeState(item){
      item.active = !item.active

      this.otherServicesIndex++

      this.calculateTotalPrice(true);

      this.getOtherServices()
    },
    accountTypesChangeState (item) {
      const index = this.accountTypes.findIndex(accountType => {
          return (item.id === accountType.id)
      })

      this.$set(this.accountTypes[index], 'active', !item.active)
      if (this.accountTypes[index].name == 'Other') {
        if (this.accountTypes[index].active) {
          this.showOtherAccountType = true
        } else {
          this.showOtherAccountType = false
          this.request.other_account_type = ''
        }
      }

      this.calculateTotalPrice(true);
      
      this.accountTypesIndex++
    },
    membershipTypesChangeState (item) {
      const index = this.membershipTypes.findIndex(membershipType => {
          return (item.id === membershipType.id)
      })

      this.$set(this.membershipTypes[index], 'active', !item.active)
      if (this.membershipTypes[index].name == 'Other') {
        if (this.membershipTypes[index].active) {
          this.showOtherMembershipType = true
        } else {
          this.showOtherMembershipType = false
          this.request.other_membership_type = ''
        }
      }

      this.calculateTotalPrice(true);
      
      this.membershipTypesIndex++
    },
    operetionalTypesChangeState (item) {
      const index = this.operetionalTypes.findIndex(operetionalType => {
          return (item.id === operetionalType.id)
      })

      this.$set(this.operetionalTypes[index], 'active', !item.active)
      if (this.operetionalTypes[index].name == 'Other') {
        if (this.operetionalTypes[index].active) {
          this.showOtherOperetionalType = true
        } else {
          this.showOtherOperetionalType = false
          this.request.other_operetional_type = ''
        }
      }

      this.calculateTotalPrice(true);
      
      this.operetionalTypesIndex++
    },
    selectOtherCountry(item){
      this.isAllLicenseTypesFetchedBlock = true;

      this.getLicenseTypesByCountry(item);
      
      this.isCountrySelected = this.getIsCountrySelected();
    },
    countriesChangeState (item) {
      const index = this.countries.findIndex(country => {
          return (item.id === country.id)
      })

      this.unActiveItem(this.countries, index, 'showOtherCountry')

      this.$set(this.countries[index], 'active', !item.active)
      if (this.countries[index].name == 'Other') {
        if (this.countries[index].active) {
          this.showOtherCountry = true
        } else {
          this.showOtherCountry = false
          this.request.other_country = ''
        }
      }
      
      this.countriesIndex++

      this.isAllLicenseTypesFetchedBlock = true;

      this.getLicenseTypesByCountry(this.countries[index]);
      
      this.isCountrySelected = this.getIsCountrySelected();
    },
    calculateTotalPrice(recalculatePartsPrice = false){
      const priceParts = ['licenseTypes', 'softwareTypes', 'accountTypes', 'membershipTypes', 'operetionalTypes', 'otherServices'];
      const pricePartsMap = {licenseTypes: 'license_price', softwareTypes: 'software_price', accountTypes: 'account_price', membershipTypes: 'membership_price', operetionalTypes: 'operetional_price', otherServices: 'other_price'};

      this.totalPrice = 0;

      if(recalculatePartsPrice){
        priceParts.forEach(property => {
          let partPrice = 0;

          this[property].forEach((item, i) => {
            if(item.active){
              let price = item.pivot && item.pivot.price ? +item.pivot.price : +item.price;
              
              partPrice += item.sale_price != undefined ? +item.sale_price : +price;
            }
          });

          if(pricePartsMap.hasOwnProperty(property)){
            this.$set(this.request, pricePartsMap[property], partPrice);
          }
        });
      }

      priceParts.forEach(property => {
        if(pricePartsMap.hasOwnProperty(property)){
          this.totalPrice += +this.request[pricePartsMap[property]];
        }
      });

      this.request.price = this.totalPrice;
    },
    getLicenseTypesByCountry(country, isFirst = false){
      if(country && country.license_types && country.license_types.length){
        Vue.set(this, 'licenseTypes', country.license_types);
      }
      else{
        Vue.set(this, 'licenseTypes', this.allLicenseTypes);
      }
      
      for (let i = 0; i < this.licenseTypes.length; i++) {
        Vue.set(this.licenseTypes[i], 'active', false);

        if(isFirst){
          for (let z = 0; z < this.userLicenseTypes.length; z++) {
              if (this.licenseTypes[i].id === this.userLicenseTypes[z].id) {
                Vue.set(this.licenseTypes[i], 'active', true);
                if (this.licenseTypes[i].name == 'Other') {
                  Vue.set(this.licenseTypes[i], 'active', true);
                }
              }
            }
        }
      }
    },
    unActiveItem(array, selectedIndex, property){
      array.forEach((item, i) => {
        if(i !== selectedIndex){
          item.active = false;
          this[property] = false;

          if(property === 'showOtherCountry'){
            this.request.other_country = ''
          }
        }
      });
    },
    getIsCountrySelected() {
      if (this.countries.length) {
        for (let i = 0; i < this.countries.length; i++) {
          const item = this.countries[i];

          if (item.active) {
            return true;
          }
        }
      }
      
      return false;
    },
    async getBankTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/bank-types')
        .then(function (response) {
          that.bankTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async getSoftwareTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/software-types')
        .then(function (response) {
          that.softwareTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async getLicenseTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/license-types')
        .then(function (response) {
          that.licenseTypes = response.data

          that.allLicenseTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    }, 
    async getCountries () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/license-countries')
        .then(function (response) {
          that.countries = response.data
          that.countries.push({name: 'Other', code: 'ot', id: null})
          that.getAllCountries();
        })
        .catch(function (error) {
          console.log(error);
        })
    }, 
    getAllCountries () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/countries')
        .then(function (response) {
          that.countries = response.data.filter(item => {
            return item.is_for_license;
          })

          that.countries.push({name: 'Other', code: 'ot', id: null})

          that.allCountries = response.data.filter(item => {
            return !that.countries.some(country => country.id === item.id)
          })
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async getAccountTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/account-types')
        .then(function (response) {
          that.accountTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async getMembershipTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/membership-types')
        .then(function (response) {
          that.membershipTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async getOperetionalTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/operetional-types')
        .then(function (response) {
          that.operetionalTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async getRequest () {
      const that = this
      const id = this.request.id

      await axios.get(process.env.VUE_APP_BACKEND_API_URL + 'license-offers/' + id + '/edit')
        .then(function (response) {
          that.request = response.data.data
          that.original_request = response.data.data

          that.userBankTypes = that.request.bankTypes
          that.userAccountTypes = that.request.accountTypes
          that.userLicenseTypes = that.request.licenseTypes
          that.userMembershipTypes = that.request.membershipTypes
          that.userOperetionalTypes = that.request.operetionalTypes
          that.userCountries = that.request.countries
          that.userSoftwareTypes = that.request.softwareTypes
          that.userOtherServices = that.request.otherServices
        })
        .catch(function (error) {
          console.log(error);
        })
    }
  },
  computed: {
    isAllBankTypesFetched() {
      if (this.bankTypes.length > 0 && this.userBankTypes.length >0) {
        return true
      }

      return false
    },
    isAllSoftwareTypesFetched() {
      if (this.softwareTypes.length > 0 && this.userSoftwareTypes.length >0) {
        return true
      }

      return false
    },
    isAllLicenseTypesFetched() {
      if (this.licenseTypes.length > 0 && this.userLicenseTypes.length > 0 && this.allLicenseTypes.length > 0) {
        return true
      }
      
      return false
    },
    isAllAccountTypesFetched() {
      if (this.accountTypes.length > 0 && this.userAccountTypes.length >0) {
        return true
      }
      
      return false
    },
    isAllMembershipTypesFetched() {
      if (this.membershipTypes.length > 0 && this.userMembershipTypes.length >0) {
        return true
      }
      
      return false
    },
    isAllOperetionalTypesFetched() {
      if (this.operetionalTypes.length > 0 && this.userOperetionalTypes.length >0) {
        return true
      }
      
      return false
    },
    isAllCountriesFetched() {
      if (this.countries.length > 0 && this.allCountries.length > 0 && this.userCountries.length >0) {
        return true
      }
      
      return false
    },
    isAllInfoFetched(){
      if(
        this.isAllAccountTypesFetched && 
        this.isAllBankTypesFetched && 
        this.isAllCountriesFetched && 
        this.isAllLicenseTypesFetched && 
        this.isAllMembershipTypesFetched && 
        this.isAllOperetionalTypesFetched && 
        this.isAllSoftwareTypesFetched
      ){
        return true
      }

      return false
    }
  },
  watch: {
    isAllBankTypesFetched: {
      handler: function(val, oldVal) {
        if(oldVal == false){
          for (let i = 0; i < this.bankTypes.length; i++) {
            this.bankTypes[i].active = false
            for (let z = 0; z < this.userBankTypes.length; z++) {
              if (this.bankTypes[i].id === this.userBankTypes[z].id) {
                this.bankTypes[i].active = true
                if (this.bankTypes[i].name == 'Other') {
                  this.showOtherBankType = true
                }
              }
            }
          }
        }
      },
      deep: true
    },
    isAllSoftwareTypesFetched: {
      handler: function(val, oldVal) {
        if(oldVal == false){
          for (let i = 0; i < this.softwareTypes.length; i++) {
            this.softwareTypes[i].active = false
            for (let z = 0; z < this.userSoftwareTypes.length; z++) {
              if (this.softwareTypes[i].id === this.userSoftwareTypes[z].id) {
                this.softwareTypes[i].active = true
                if (this.softwareTypes[i].name == 'Other') {
                  this.showOtherSoftwareType = true
                }
              }
            }
          }
        }
      },
      deep: true
    },
    isAllLicenseTypesFetched: {
      handler: function(val, oldVal) {
        if(!this.isAllLicenseTypesFetchedBlock && val && !this.isAllInfoFetched){
          for(let i = 0; i < this.licenseTypes.length; i++) {
            this.licenseTypes[i].active = false
            
            for (let z = 0; z < this.userLicenseTypes.length; z++) {
              if (this.licenseTypes[i].id === this.userLicenseTypes[z].id) {
                this.licenseTypes[i].active = true

                if (this.licenseTypes[i].name == 'Other') {
                  this.showOtherLicenseType = true
                }
              }
            }
          }
        }
      },
      deep: true
    },
    isAllAccountTypesFetched: {
      handler: function(val, oldVal) {
        if(oldVal == false){
          for (let i = 0; i < this.accountTypes.length; i++) {
            this.accountTypes[i].active = false
            for (let z = 0; z < this.userAccountTypes.length; z++) {
              if (this.accountTypes[i].id === this.userAccountTypes[z].id) {
                this.accountTypes[i].active = true
                if (this.accountTypes[i].name == 'Other') {
                  this.showOtherAccountType = true
                }
              }
            }
          }
        }
      },
      deep: true
    },
    isAllMembershipTypesFetched: {
      handler: function(val, oldVal) {
        if(oldVal == false){
          for (let i = 0; i < this.membershipTypes.length; i++) {
            this.membershipTypes[i].active = false
            for (let z = 0; z < this.userMembershipTypes.length; z++) {
              if (this.membershipTypes[i].id === this.userMembershipTypes[z].id) {
                this.membershipTypes[i].active = true
                if (this.membershipTypes[i].name == 'Other') {
                  this.showOtherMembershipType = true
                }
              }
            }
          }
        }
      },
      deep: true
    },
    isAllOperetionalTypesFetched: {
      handler: function(val, oldVal) {
        if(oldVal == false){
          for (let i = 0; i < this.operetionalTypes.length; i++) {
            this.operetionalTypes[i].active = false
            for (let z = 0; z < this.userOperetionalTypes.length; z++) {
              if (this.operetionalTypes[i].id === this.userOperetionalTypes[z].id) {
                this.operetionalTypes[i].active = true
                if (this.operetionalTypes[i].name == 'Other') {
                  this.showOtherOperetionalType = true
                }
              }
            }
          }
        }
      },
      deep: true
    },
    isAllCountriesFetched: {
      handler: function(val, oldVal) {
        if(oldVal == false){
          for (let i = 0; i < this.countries.length; i++) {
            this.countries[i].active = false
            for (let z = 0; z < this.userCountries.length; z++) {
              if (this.countries[i].id === this.userCountries[z].id) {
                this.countries[i].active = true

                this.getLicenseTypesByCountry(this.countries[i], true);
              }
            }
          }
          
          const userCountryIds = this.userCountries.map(country => country.id)
          
          for (let i = 0; i < this.allCountries.length; i++) {
            if (userCountryIds.includes(this.allCountries[i].id)) {
              const nullIndex = this.countries.findIndex(country => country.id === null)
              
              if (nullIndex !== -1) {
                this.countries[nullIndex].active = true
                this.showOtherCountry = true
                this.request.other_country = this.allCountries[i]
                
                this.getLicenseTypesByCountry(this.allCountries[i], true);

                break
              }
            }
          }
          this.countriesIndex++
          this.allCountriesIndex++

          this.isCountrySelected = this.getIsCountrySelected();
        }
      },
      deep: true
    },
    isAllInfoFetched:{
      handler: function(val){
        if(val){
          /*this.originalTotalPrice = this.calculateTotalPrice(true)
          this.totalPrice = this.originalTotalPrice

          if(+this.totalPrice != +this.request.price){
            this.totalPrice = this.request.price
          }*/
        }
      },
      deep: true,
    },
    /*'request.other_country': {
      handler: function(newVal, oldVal) {
        if(newVal != oldVal){
          this.getLicenseTypesByCountry(newVal);
        }
      },
      deep: true,
    }*/
  }
}
</script>

<style lang="scss" scoped>
.page-request {
  .box {
    margin: 0;
    position: relative;
    &_list{
      z-index: 9;
      .list_item{
        position: relative;
      }
    }
    &-icon{
      width: 80%;
      height: 80%;
      display: block;
      margin-left: auto;
      margin-top: auto;
      &_wrap{
        position: absolute;
        top: 24px;
        right: 24px;
        left: 24px;
        bottom: 24px;
        transform: rotate(15deg) scale(.95);
        transform-origin: center center;
        opacity: .2;
        &.software{
          right: unset;
          margin-left: 96px;
          transform: rotate(15deg) scale(2.2);
        }
      }
    }
    &-group{
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      align-items: stretch;
    }
    &_payments {
      background: transparent;
      box-shadow: none;
      .title{
        color: #09090B;
        padding-left: 0;
        &:before{
          background: #1763fb;
          content: none;
        }
      }

      .content {
        padding-top: 0;
        color: #09090B;

        .block {
          padding: 0;
          padding-bottom: 1rem;

          .ttl {
            text-align: center;
            color: #09090B;

            svg.img {
              width: 2.5rem;
              height: 2.5rem;
            }
          }

          .main {
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 24px;
            align-items: stretch;

            &_center {
              width: 45%;
            }

            .box {
              &_list.row-list{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                &.flex-wrap{
                  flex-wrap: wrap;
                  gap: 24px
                }
                .other-services-tmblr-col{
                  width: 25%;
                  flex: 0 0 25%;
                  max-width: 25%;
                  margin-bottom: 8px;
                }
              }
              &_tmblers {
                padding: 1.5rem;
                border: 1px solid #454655;
                border-radius: 5px;
                background: #fff;
                box-shadow: none;
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;

                .box_list-2 {
                  display: flex;
                  flex-wrap: wrap;
                  padding-bottom: .75rem;

                  .list_item {
                    min-width: 45%;

                    @media (max-width: 1600px) {
                      width: 100%;
                    }
                  }
                }

                .row {
                  display: flex;
                }

                .box_ttl {
                  font-size: 14px;
                  padding-bottom: .5rem;
                  display: flex;
                  justify-content: space-between;
                  .box_price{
                    color: #1763fb
                  }
                }

                .inpt {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  padding: 16px 24px !important;
                  position: relative;
                  align-self: stretch;
                  width: 100%;
                  flex: 0 0 auto;
                  border-radius: 5px;
                  border: 1px solid;
                  border-color: #ababb2;
                  margin-bottom: 0 !important;
                  color: #09090b;
                }
                select.inpt{
                  padding: 4px 24px !important;
                }
              }

              &_left {
                width: 25%;

                @media (max-width: 1440px) {
                  padding-left: 1.5rem;
                }
              }

              &_right {
                width: 25%;

                @media (max-width: 1440px) {
                  padding-right: 1.5rem;
                }
              }

              &_arrow-r {
                position: relative;
                margin-right: 3rem;

                .arrow-r {
                  top: 50%;
                  right: 0;
                  transform: translate(50%, -50%);
                }
              }

              &_arrow-l {
                position: relative;
                margin-left: 3rem;

                .arrow-r {
                  top: 50%;
                  left: 0;
                  transform: translate(-50%, -50%);
                }
              }

              &_prcs {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(19, 180, 151, 0.1);
                padding: 1rem 0;

                > :nth-child(2) {
                  border-left: 2px solid rgba(19, 180, 151, 0.1);
                  border-right: 2px solid rgba(19, 180, 151, 0.1);
                }

                .block {
                  width: calc(33% - .5rem);
                  padding: .25rem 1.5rem;

                  .ttl {
                    font-size: 10px;
                    text-align: left;
                    color: rgba(255, 255, 255, 0.5);
                  }

                  .val {
                    font-size: 1.5rem;

                    &-text {
                      font-size: 1rem;
                    }
                  }
                }
              }
            }
          }

          &__bankAcc {
            .ttl {
              svg.img {
                ::v-deep path {
                  fill: #24BAD6;
                }
              }
            }
          }

          &__merchantAcc {
            .ttl {
              svg.img {
                ::v-deep path {
                  fill: #FF056C;
                }
              }
            }
          }
        }

        .footer {
          display: flex;
          justify-content: flex-end;
          padding: 0 !important;
          flex-wrap: wrap;

          .btn {
            &_submit {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: horizontal;
              -webkit-box-direction: reverse;
              -ms-flex-direction: row-reverse;
              flex-direction: row-reverse;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              min-height: 3.125rem;
              font-size: 1.25rem;
              cursor: pointer;
              border-radius: 0.3125rem;
              border: 0.125rem solid #3177ff;
            }
          }
          .d-flex{
            display: flex;
          }
          .w-100{
            width: 100%;
            flex: 0 0 100%;
          }
          .total-price{
            &__wrap{
              justify-content: space-between;
              align-items: center;
              margin-bottom: 12px;
            }
            &__title{
              color: #09090b;
              font-size: 20px;
              margin: 0 0;
            }
            &__value{
              color: #3177ff;
              font-size: 20px;
              margin: 0 0;
            }
          }
        }
        .row{
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: space-between;
          gap: 24px;
          margin-bottom: 24px;
        }
        .col-other-services{
          width: 40%;
          flex: 0 0 40%;
          display: flex;
          gap: 12px;
        }
        .inpt{
          height: 2.75rem;
          color: #09090B;
          padding: 0.25rem 0.75rem;
          background-color: inherit;
          border: 1px solid #1763fb;
          border-radius: 6px;
          &.flexible{
            width: 70%;
            max-width: 70%;
    
          }
        }
        .btn{
          border: none;
          border-radius: 3px;
          background: #1763fb;
          color: #fff;
          height: 2.75rem;
          padding: 0.25rem 0.75rem;
          width: 25%;
        }
      }
    }
  }

  .arrow-r {
    position: absolute;
    width: 3rem;
    height: 3rem;
    background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep svg {
      width: 65%;
      height: 65%;

      path {
        fill: #ffffff;
      }
    }
  }

  // .inpt {
    // &_procCurr {
    //   width: 50%;
    //   margin-left: 45%;
    // }
  // }
}
.position-relative{
  position: relative;
}
.box--blur{
  filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  -moz-filter: blur(4px);
  -webkit-filter: blur(4px);
}
.box_overlay--blur{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 1.5rem;
  border: 1px solid #ababb2;
  border-radius: 5px;
  background: #fff;
  opacity: .75;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 1 auto;
  h4{
    text-align: center;
  }
}
.box--blur__wrapper{
  flex: 1 1 auto;
  display: flex;
  width: 25%;
}
.form-control {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 16px 24px !important;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  border-radius: 5px;
  border: 1px solid;
  border-color: #ababb2;
  margin-bottom: 0 !important;
}

.invalid-feedback {
    font-size: 10px;
    width: 100%;
    margin-top: .25rem;
    color: #dc3545;
}
.pointer-events-none{
  pointer-events: none;
}
.disabled-overlay{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  pointer-events: none;
}
.modal {
  &__header{
    padding: 24px;
    .sbttl{
      font-style: normal !important;
    }
    .ttl{
      padding: 0;
      &:before{
        content: none;
      }
    }
  }
  &__body{
    padding: 0 24px;
  }
  &__wrapper {
    width: 540px;
    max-width: 100%;
  }

  &__close {
    .btn::before {
      content: '+';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      color: white;
      font-size: 2.25rem;
      font-weight: 300;
    }
  }

  &__body {
    flex-direction: column;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      margin: .5rem 0;
      flex-wrap: wrap;
      .data {
        display: flex;  
        flex: 1 0 100%;
        width: 100%;
      }
    }
    input, textarea {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 16px 24px !important;
      position: relative;
      align-self: stretch;
      width: 100%;
      flex: 0 0 auto;
      border-radius: 5px;
      border: 1px solid;
      border-color: #ababb2;
      margin-bottom: 0 !important;
      resize: none;
      &::placeholder {
        color: #09090b;
        opacity: 1; /* Firefox */
      }
      
      &::-ms-input-placeholder { /* Edge 12 -18 */
        color: #09090b;
      }
    }
    textarea{
      height: 120px;
      padding: 4px 24px !important;
    }

    select {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 4px 24px !important;
      position: relative;
      align-self: stretch;
      width: 100%;
      flex: 0 0 auto;
      border-radius: 5px;
      border: 1px solid;
      border-color: #ababb2;
      margin-bottom: 0 !important;
    }

    .input-group__col{
      gap: 1rem;
      input{
        width: calc(65% - 1rem);
        max-width: calc(65% - 1rem);
      }
      select{
        width: 35%;
        max-width: 35%;
      }
    }

    .label {
      // min-width: 10rem;
      font-weight: bold;
      padding-right: 1rem;
    }

    .data {
      .inpt {
        color: var(--c_black);
        outline: none;
        -webkit-appearance: none;
      }
    }

    .invalid-feedback {
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #dc3545;
    }

    // .form-control {
    //   width: 100%;
    //   height: 100%;
    //   color: #ffffff;
    //   background-color: #1C2126;
    //   padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    //   margin: auto 0rem;
    //   border: 1px solid #2F363D;
    //   border-radius: 0.75rem;
    //   box-shadow: 0px 11px 23px rgb(0 0 0 / 2%);
    //   outline: none;
    //   -webkit-appearance: none;
    // }
  }

  &__footer {
    padding: 24px;
    .btn {
      font-size: 18px;
      padding: 1rem 3rem;
      margin: 0;
      background: #1763fb;
      box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
      border-radius: 6px;
    }
  }
}
.add-dataset{
  position: absolute;
  right: -18px;
  top: 0;
  bottom: 0;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  svg{
    fill: #1763fb;
    max-width: 16px;
    max-height: 16px;
    path{
      stroke: #1763fb;
    }
  }
}
.tmblr.pointer-events-none{
  padding-right: 8px;
}
.block {
  margin-bottom: 16px;
  &_check {

    .ttl {
      padding-bottom: .25rem;
    }

    ::v-deep .checkBox {
      .label {
        font-size: 6px;
        font-weight: 400;
      }
    }
  }

  &_files {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    .box_addFiles {
      padding: 1rem 1.75rem;
      background: transparent;
      box-shadow: none;
      border: 1px solid #ababb2;
      display: flex;
      flex-direction: column;
      margin: 0;
      .ttl {
        color: #09090b;
      }

      .separ {
        width: 3rem;
        height: .25rem;
        background: #1763fb;
        box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
        border-radius: 6px;
        margin: .25rem 0;
        display: none !important;
      }

      .content {
        min-height: 8rem;
        flex-grow: 1;
        margin-top: 1rem;
        border: 1px dashed #1763fb;
        box-sizing: border-box;
        filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
        border-radius: 6px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .choose {
          text-align: center;

          .btn {
            padding: 1rem 1.75rem;
            margin: .5rem;
            background: #1763fb;
            border-radius: 6px;
            color: #fff;
            line-height: 1.1;
          }

          .desc {
            font-size: 12px;
            color: #09090b;
          }
        }

        &_many {
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 0.75rem 6px;

          .draggable {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
          }

          .file {
            position: relative;
            text-align: center;
            padding: .75rem .5rem .5rem;
            width: 6.25rem;
            max-width: 6.25rem;

            svg.img {
              width: 3rem;
              height: 3rem;
              margin: 0 auto;
            }

            .btn {
              &_del {
                position: absolute;
                top: 0;
                right: 1rem;
                width: 1.25rem;
                height: 1.25rem;
                border-radius: 50%;
                background-color: #B03636;
                display: flex;
                align-items: center;
                justify-content: center;

                svg.img {
                  width: 40%;
                  height: 40%;
                }
              }
            }

            .desc {
              font-size: 10px;
              color: #09090b;
              word-break: break-word;
            }
          }

          .add {
            text-align: center;
            padding: 0 .5rem .5rem;
            width: 6rem;
            max-width: 6rem;

            .btn {
              width: 2.5rem;
              height: 2.5rem;
              margin: .75rem auto .25rem;
              border-radius: 50%;
              background: #1763fb;
              display: flex;
              align-items: center;
              justify-content: center;

              svg.img {
                width: 45%;
                height: 45%;
                transform: rotate(45deg);
              }
            }

            .desc {
              font-size: 12px;
              color: #09090b;
            }
          }
        }
      }
    }
  }
}
.impulse-info-wrap{
  display: flex;
  align-items: center;
  font-size: 19px;
  svg{
    max-width: 32px;
    max-height: 32px;
    fill: #1763fb;
    margin-right: 12px;
    transform: scale(1);
	  animation: pulse 2s infinite;
  }
}
@keyframes pulse {
	0% {
		transform: scale(0.9);
	}

	70% {
		transform: scale(1.1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.9);
	}
}
</style>